import React from "react";
import {Link} from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import {motion} from "framer-motion";
const ProjectCard = (props) => {
    const { image, title, completed, scope, location, client, link } = props;
    return (
        <Link to={link}>
            <div
                className={
                    "shadow-md transition duration-500 ease-in-out hover:shadow-lg"
                }
            >
                <div className={"overflow-hidden h-96 "}>
                    <div
                        className={
                            "transition duration-1000 ease-in-out overflow-hidden h-96 transform hover:-translate hover:scale-110 "
                        }
                    >
                        <motion.div
                            animate={{ opacity: 1 }}
                            initial={{ opacity: 0 }}
                            transition={{ delay: 1 }}
                            style={{ backgroundImage: `url(${image})` }}
                            className={
                                " bg-epg-lightgray bg-center bg-cover bg-no-repeat h-96 w-full"
                            }
                            src={image}
                            alt="BigCo Inc. logo"
                        />
                    </div>
                </div>
                <div
                    className={
                        "bg-epg-lightgray p-5 h-80 md:h-80 justify-between flex flex-col"
                    }
                >
                    <div>
                        <h3
                            className={"font-exo2 font-bold lg:text-2xl mb-4 text-xl lg-h-32  text-epg-gray"}
                        >
                            {title}
                        </h3>
                        <p className={"font-exo2  text-epg-gray text-sm leading-7 md:text-md"}>
                            {client}
                        </p>
                        <p className={"font-exo2 text-epg-gray text-sm leading-7 md:text-md"}>
                            {completed}
                        </p>
                        <p className={"font-exo2 text-epg-gray text-sm  leading-7 md:text-md"}>
                            {location}
                        </p>
                        <p className={"font-exo2 text-epg-gray text-sm  leading-7 md:text-md"}>
                            {scope}
                        </p>
                    </div>
                    <a
                        className={
                            "font-exo2 uppercase text-epg-gray lg:text-lg text-xs font-bold leading-7"
                        }
                        href="/"
                    >
                        Read more
                        <FontAwesomeIcon
                            className={"ml-1"}
                            color={"#454545"}
                            size={"sm"}
                            icon={faChevronCircleRight}
                        />
                    </a>
                </div>
            </div>
        </Link>
    );
};

export default ProjectCard
