import * as React from "react";
import PageLayout from "../../PageLayout";
import SectionLayout from "../../SectionLayout";
import { Helmet } from "react-helmet";
import HowWeCanHelp from "../../components/HowWeCanHelp";
import img1 from "../../images/previousprojects/1.jpg";
import img2 from "../../images/previousprojects/2.jpg";
import img3 from "../../images/previousprojects/3.jpg";
import img4 from "../../images/previousprojects/4.jpg";
import img5 from "../../images/previousprojects/5.jpg";
import img6 from "../../images/previousprojects/6.jpg";
import ProjectCard from "../../components/ProjectCard";
const PreviousProjects = () => {
    return (
        <PageLayout
            noButton
            title={"Our Projects"}
            subtitle={"Territory-wide service"}
            navSize={"h-96"}
            titleStyles={"xl:mt-12 lg:mt-16 md:mt-16 sm:mt-20 xs:mt-20 mt-16"}
        >
            <Helmet>
                <title>
                    EPG Solutions | Our Projects
                </title>
                <meta
                    name="description"
                    content="Proudly providing remote power generation services since 2013. Power stations, bore fields, fuel transfer systems and chlorine disinfection services."
                />
            </Helmet>
            <div className={"bg-epg-yellow h-5"} />
            <SectionLayout bgColor={"bg-epg-lightgray"}>
                <p
                    className={
                        "text-center font-exo2 leading-8 text-xl py-5"
                    }
                >
                    It gives E-P-G Solutions great pride to be able be part of these
                    Territory-building projects. Whatever the size or time commitment of
                    each project, our flexibility and strong work ethic allows us to
                    carefully plan to meet deadlines while delivering high quality
                    solutions that are built to last. Look at some of the projects E-P-G
                    Solutions has been part of in the last 12 months.
                </p>
            </SectionLayout>
            <SectionLayout bgColor={"bg-epg-white p-5"}>
                <div className={"grid md:grid-cols-2 xl:grid-cols-2 gap-10 my-5"}>
                    <ProjectCard
                        link={"/previous-projects/chlorine-containers-disinfection-program/"}
                        image={img1}
                        text={`Client: Power Water Completed: Ongoing Location: Belyuen, Daly River, Urapunga, Bulman, Jilkminggan. Scope: 10-Foot chlorine`}
                        client={"Client: Power Water"}
                        completed={"Completed: Ongoing"}
                        location={
                            "Location: Belyuen, Daly River, Urapunga, Bulman, Jilkminggan."
                        }
                        scope={"Scope: 10-Foot chlorine"}
                        title={"Chlorine containers – disinfection program"}
                    />
                    <ProjectCard
                        link={"/previous-projects/bore-panel-upgrades/"}
                        image={img2}
                        client={"Client: Power Water"}
                        completed={"Completed: Ongoing"}
                        location={
                            "Location: Stirling (Willora), Tara (Neutral Junction), Ali Curung"
                        }
                        scope={"Scope: Installation"}
                        title={
                            "Bore panel upgrades, ABB Water Master & Mag Flows, Siemens Pressure transducer installations"
                        }
                    />
                    <ProjectCard
                        link={"/previous-projects/fuel-transfer-systems/"}
                        image={img3}
                        client={"Client: Power Water"}
                        completed={"Completed: 2019-20"}
                        location={
                            "Location: Ramingining, Goulbourn Island, Alcoota, Umbakumba, Stirling (Wilora)"
                        }
                        scope={"Scope: Installation"}
                        title={"Fuel transfer systems"}
                    />
                    <ProjectCard
                        link={"/previous-projects/peppimenarti-power-station/"}
                        image={img4}
                        text={"Client: Power Water Completed: 2019-20 Location: Scope:"}
                        client={"Client: Power Water"}
                        completed={"Completed: 2019-20"}
                        location={
                            "Location: Peppimenarti, Top End region (320km SW of Darwin)"
                        }
                        title={"Peppimenarti Power Station – Compact 2000"}
                    />
                    <ProjectCard
                        link={"/previous-projects/eva-valley-power-station/"}
                        image={img5}
                        client={"Client: Power Water"}
                        completed={"Completed: 2019-20"}
                        location={
                            "Location: Eva Valley, Top End region (110km S of Katherine)"
                        }
                        title={"Eva Valley Power Station"}
                    />
                    <ProjectCard
                        link={"/previous-projects/laramba-borefield-power-station/"}
                        image={img6}
                        client={"Client: Power Water – Remote Water Services"}
                        completed={"Completed: 2019-20"}
                        location={"Location: Laramba, Central Australia (205km NW)"}
                        title={"Laramba Borefield Power Station"}
                    />
                </div>
            </SectionLayout>
            <HowWeCanHelp />
        </PageLayout>
    );
};

export default PreviousProjects;
