import React from 'react';
import SectionLayout from "../SectionLayout";
import {Link} from "gatsby";

const HowWeCanHelp = () => {
    return(
        <SectionLayout bgColor={"bg-epg-mediumgray"}>
            <div
                className={"bg-epg-mediumgray  py-12 justify-between flex-col grid lg:grid-cols-2"}
            >
                <div>
                    <h4 className={"text-3xl lg:text-4xl text-epg-gray font-bold font-exo2"}>
                        Find out how we can help you
                    </h4>
                    <p className={"font-exo2 text-epg-gray lg:leading-9 lg:text-lg text-md leading-7  mt-8"}>
                        We’ve got the experience, the knowledge and the runs on the board
                        to get the most challenging projects done. Get in touch to find
                        out how we can help.
                    </p>
                </div>
                <div className={"flex justify-start lg:items-center lg:justify-end "}>
                    <Link to={"/contact-us"}>
                        <button
                            className={
                                "mt-8 lg:mt-0 bg-epg-yellow w-56 focus:outline-none hover:bg-epg-white px-6 lg:h-16 h-12 font-exo2 lg:text-2xl text-xl font-bold rounded-md self-center "
                            }
                            type="button"
                        >
                            Get in touch
                        </button>
                    </Link>
                </div>
            </div>
        </SectionLayout>
    )
}

export default HowWeCanHelp
